import {store} from '@/store/store'

export default function user({next, router} : any){
  const user = store.getters['auth/getUser']
  if (user && Object.keys(user).length > 0) {
    next({ name: 'Home' })
  }
  else {
    next()
  }
}
