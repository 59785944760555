import { IEpisode } from '@/interfaces/episode';
import { IHomeContent } from '@/interfaces/homeContent';
import { IProgram } from '@/interfaces/program';
import axios from 'axios';
import { Ref } from 'vue';

export default class MediaService {
    public async getEpisodeInfo(id: string): Promise<IEpisode>{
        return axios.get('client/episodes' + id);
    }

    public async getEpisodesByProgramAndSeason(idProgram: string, season: string): Promise<Array<IEpisode>>{
        return axios.get('client/episodes/' + idProgram + '/' + season);
    }

    public async getPrograms(): Promise<IHomeContent>{
        return axios.get('client/programs');
    }
    public async getProgramDetails(idProgram: string): Promise<IProgram>{
        return axios.get('client/programs/'+idProgram);
    }
    
    public async search(searchText: Ref<string>, category: string): Promise<Array<IProgram>>{
        return axios.get('client/search?keywords='+searchText.value+'&category='+category);
    }

    public async getFavorites(): Promise<Array<any>>{
        return axios.get('client/favorites');
    }
    
    public async addFavorites(idProgram: string): Promise<Array<IProgram>>{
        return axios.post('client/favorites', {idProgram});
    }
    
    public async removeFavorites(idProgram: string): Promise<Array<IProgram>>{
        return axios.delete('client/favorites/'+ idProgram);
    }

    public async getCategories(): Promise<Array<any>>{
        return axios.get('client/categories');
    }
}