import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import VuexPersister from 'vuex-persister'
import { mediaModule, MediaState } from './media';
import { authModule, AuthState } from './auth';

const vuexPersister = new VuexPersister<State>({
  // ...your options
})

export interface AppState {
  isMobile: boolean
}

const appModule = {
  namespaced: true,
  state: (): AppState => ({
    isMobile: window.innerWidth < 500 ? true : false,
  }),
  getters: {},
  mutations: {
    SET_IS_MOBILE(state: any, payload: boolean) {
      state.isMobile = payload
    },
  },
  actions: {
    setIsMobile({ commit }: any, payload: boolean) {
      commit("SET_IS_MOBILE", payload);
    },
    setAccessToken({ commit }: any, payload: string) {
      commit("SET_ACCESS_TOKEN", payload);
    }
  }
}

export interface State {
  auth: AuthState
  app: AppState
  media: MediaState
}

export const key: InjectionKey<Store<State>> = Symbol();


export const store = createStore<State>({
  modules: {
    auth: authModule,
    app: appModule,
    media: mediaModule,
  },
  state: {} as State,
  plugins: [vuexPersister.persist]
})
export function useStore() {
  return baseUseStore(key)
}