import MediaService from '@/services/media.service'
import { IEpisode } from '@/interfaces/episode';
import { isArray } from '@vue/shared';
import { IProgram } from '@/interfaces/program';
import { Ref } from 'vue';
import { IHomeContent } from '@/interfaces/homeContent';

const mediaService = new MediaService();

export interface MediaState {
    programs: IHomeContent,
    currentEpisodes: IEpisode[],
    selectedProgram: IProgram,
    sarchedPrograms: Array<IProgram>,
    favorites: Array<any>,
    categories: Array<any>
  }
  
export const mediaModule = {
    namespaced: true,
    state: (): MediaState => ({
     currentEpisodes: [],
     programs: {} as IHomeContent,
     selectedProgram: {} as IProgram,
     sarchedPrograms: [],
     favorites: [],  
     categories: []    
    }),
    getters: {
      getPrograms(state: any) {
        return state.programs;
      },
      getFavorites(state: any) {
        return state.programs;
      },
      getCategories(state: any) {
        return state.categories;
      },
      getCurrentEpisodes(state: any) {
        return state.currentEpisodes;
      },
      getSelectedProgram(state: any) {
        return state.selectedProgram;
      } 
    },
    mutations: {
      SET_PROGRAMS(state: any, payload: Array<IProgram>) {
        state.programs = payload
      },
      SET_EPISODES(state: any, payload: Array<IEpisode>) {
        state.currentEpisodes = payload
      },
      SET_SELECTED_PROGRAM(state: any, payload: IProgram) {
        state.selectedProgram = payload
      },
      SET_SEARCHED_PROGRAMS(state: any, payload: Array<IProgram>) {
        state.sarchedPrograms = payload
      }, 
      SET_FAVORITES(state: any, payload: Array<any>) {
        state.favorites = payload
      }, 
      SET_CATEGORIES(state: any, payload: Array<any>) {
        state.categories = payload;
        state.categories.forEach((e:any) => {
          e.selected = false;
        });
      },
      ADD_FAVORITE(state: any, payload: boolean) {
        if (payload) {
          state.programs.evidence[0].favorite = true;
          state.favorites.push(state.programs.evidence[0]);
        } else {
          state.selectedProgram.favorite = true;
          state.favorites.push(state.selectedProgram);
        }
        
      },
      REMOVE_FAVORITE(state: any, payload: boolean) {
        if (payload) {
          state.programs.evidence[0].favorite = false;
          state.favorites.filter((e:IProgram) => {
            return e.idProgram !=  state.programs.evidence[0].idProgram
          });
        } else {
          state.selectedProgram.favorite = false;
          state.favorites.filter((e:IProgram) => {
            return e.idProgram != state.selectedProgram.idProgram
          });
        }
      },
    },
    actions: {
      /* search({ commit }: any, searchText: Ref<string>, categories: Array<any>) {
        if (searchText.value) {
          mediaService.search(searchText, categories).then((response: any) => {
            if (response && response.data && response.data.data && response.status == 200) {
              commit("SET_SEARCHED_PROGRAMS", response.data.data);
            }
          })
        } else {
          commit("SET_SEARCHED_PROGRAMS", []);
        }
        
      }, */
      getPrograms({ commit }: any) {
        mediaService.getPrograms().then((response: any) => {
          if (response && response.data && response.data.data && response.status == 200) {
            const formattedPrograms = response.data.data;
            for (const property in formattedPrograms) {
              if (!isArray(formattedPrograms[property])) {
                formattedPrograms[property] = [formattedPrograms[property]];
              }
            }
            commit("SET_PROGRAMS", formattedPrograms);
          }
        })
      },
      getProgramDetails({ commit }: any, payload: {idProgram: string}) {
        mediaService.getProgramDetails(payload.idProgram).then((response: any) => {
          if (response && response.data && response.data.data && response.status == 200) {
            commit("SET_SELECTED_PROGRAM", response.data.data);
          }
        })
      },
      getEpisodesByProgramAndSeason({ commit }: any, payload: {idProgram: string, season: string}) {
        mediaService.getEpisodesByProgramAndSeason(payload.idProgram, payload.season).then((response: any) => {
          if (response && response.data && response.status === 200) {
            commit("SET_EPISODES", response.data.data);
          }
        })
      },
      getFavorites({ commit }: any) {
        mediaService.getFavorites().then((response: any) => {
          if (response && response.data && response.status === 200) {
            commit("SET_FAVORITES", response.data.data);
          }
        })
      },
      getCategories({ commit }: any) {
        mediaService.getCategories().then((response: any) => {
          if (response && response.data && response.status === 200) {
            commit("SET_CATEGORIES", response.data.data);
          }
        })
      },
      addFavorite({ commit }: any, payload: {idProgram: string, isEvidence: boolean}) {
        mediaService.addFavorites(payload.idProgram).then((response: any) => {
          if (response && response.data && response.status === 200) {
            commit("ADD_FAVORITE", payload.isEvidence);
          }
        })
      },
      removeFavorite({ commit }: any, payload: {idProgram: string, isEvidence: boolean}) {
        mediaService.removeFavorites(payload.idProgram).then((response: any) => {
          if (response && response.data && response.status === 200) {
            commit("REMOVE_FAVORITE", payload.isEvidence);
          }
        })
      },
      /* setSelectedProgram({ commit }: any, payload: IProgram) {
            commit("SET_SELECTED_PROGRAM", payload);
      }, */
    }
  }