
import { useStore } from "./store/store";
import { IonApp } from '@ionic/vue';
import { defineComponent, onMounted } from 'vue';
import {
  IonRouterOutlet
} from '@ionic/vue';
import { home, play, search } from 'ionicons/icons';
import { useRoute } from 'vue-router';
import { computed } from 'vue'
import { StatusBar } from '@capacitor/status-bar';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },

  setup() {
    const route = useRoute();
    const store = useStore()
    const isMobile = computed(() => store.state.app.isMobile);
    const onResize = () => {
      store.dispatch('app/setIsMobile', window.innerWidth < 500 ? true : false)
    }
    return {
      home,
      play,
      search,
      route,
      store,
      isMobile,
      onResize
    }
  },
  mounted() {
    StatusBar.setBackgroundColor({ color: '#000000' });
    NavigationBar.setColor({ color: '#000000' });
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
});
