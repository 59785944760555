import UserService from '@/services/user.service'
import { IUser } from '@/interfaces/user';
import router from '@/router/index'

const userService = new UserService();

export interface AuthState {
  user: IUser,
  accessToken: string
}
 
export const authModule = {
  namespaced: true,
  state: (): AuthState => ({
    user: {},
    accessToken: ''
  }),
  getters: {
    getAccessToken(state: any) {
      return state.accessToken;
    },
    getUser(state: any) {
      return state.user;
    } 
  },
  mutations: {
    LOGIN(state: any, payload: IUser) {
      //state.user.name = payload.name
      state.user.email = payload.email
    },
    LOGOUT(state: any) {
      state.user = {};
      state.accessToken = ''
    },
    REGISTER(state: any, payload: IUser) {
      state.user = payload;
    },
    UPDATE_USER(state: any, payload: IUser) {
      state.user = payload
    },
    SET_ACCESS_TOKEN(state: any, payload: string) {
      state.accessToken = payload
    },
  },
  actions: {
    login({ commit }: any, payload: IUser) {
      userService.login(payload).then((response) => {
        if (response && response.data && response.data.status === 'success') {
          commit("LOGIN", payload);
          commit("SET_ACCESS_TOKEN", response.data.token);
          router.replace({ name: 'Home' })
        }
      }).catch(() => {
          commit('LOGOUT');
        })
    },
    register({ commit }: any, payload: IUser) {
      userService.register(payload).then((response) => {
        if (response && response.data && response.data.status === 'success') {
          router.replace({ name: 'RegistrazioneConfermata' })
        }
      })
    },
    updateUser({ commit }: any, payload: IUser) {
      userService.updateUser(payload).then((response) => {
        if (response && response.data && response.data.status === 'success') {
          commit("UPDATE_USER");
        }
      })
    },
    updatePassword({ commit }: any, payload: any) {
      userService.updatePassword(payload).then((response) => {
        if (response && response.data && response.data.status === 'success') {
          commit("UPDATE_PASSWORD");
        }
      })
    }, 
    deleteProfile({ commit }: any, payload: any) {
      userService.deleteProfile(payload).then((response) => {
        if (response && response.data && response.data.status === 'success') {
          commit('LOGOUT');
          router.replace({ name: 'Login' })

        }
      })
    },
    /*sendResetPassEmail({ commit }: any, payload: any) {
      userService.resetPasswordEmail(payload).then((response) => {
        if (response && response.data && response.data.status === 'success') {
          
        }
      })
    },*/
    logout({ commit }: any) {
      commit("LOGOUT"); 
      router.replace({ name: 'Login' })
    }
  }
}