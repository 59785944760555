import { IUser } from '../interfaces/user';
import axios from 'axios';


export default class UserService {
    public async updateUser(user:IUser): Promise<any>{
        return axios.put('/client/update-user', user);
    }
    public async updatePassword(passForm: any): Promise<any>{
        return axios.put('/client/update-password', passForm);
    }
    public async resetPasswordEmail(user: any): Promise<any>{
        return axios.post('password/email', user);
    }
    public async resetPassword(user: any, token:any): Promise<any>{
        return axios.post('password/reset', user);
    }
    public async register(user:IUser): Promise<any>{
        return axios.post('/register', user);
    }
    public async login(user:IUser): Promise<any>{
        return axios.post('/login', user);
    }
    public async deleteProfile(user:IUser): Promise<any>{
        return axios.post('client/delete-profile', user);
    }
    public async logout(): Promise<any>{
        return axios.post('/logout');
    }
}