import axios from 'axios'
import { store } from '../store/store'
import { loadingController, toastController } from '@ionic/vue';
// import i18n from '~/plugins/i18n'

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
//axios.defaults.baseURL = 'https://vtm.ilbrandificio.it/api';
let loading: HTMLIonLoadingElement;
// Request interceptor
axios.interceptors.request.use(async (request: any) => {
  loading = await loadingController.create({
    cssClass: '',
    spinner: 'bubbles',
    mode: 'ios',
    translucent: true
  });
  const lastLoader = await loadingController.getTop();
  if (!lastLoader) {
    loading.present();
  }
  const token = store.getters['auth/getAccessToken']
  if (token) {
    if (request && request.headers) {
      request.headers['Authorization'] = `Bearer ${token}`
    }
  }
  return request
})

// Response interceptor
axios.interceptors.response.use(async (response: any) => {
  loadingController.dismiss();
  const toast = await toastController.create({
    message: '',
    duration: 1500,
    position: 'top'
  });
  toast.setAttribute('color', response.data.success || response.data.status == 'success' ? 'success' : 'danger')
  if (response.config.method == 'put') {
    //toast.setAttribute('message', 'Aggiornato Correttamente');
    //toast.present();
  }
  else if (response.config.method == 'post') {
    //toast.setAttribute('message', 'Inserito Correttamente');
    //toast.present();
  }
  else if (response.config.method == 'delete') {
    //toast.setAttribute('message', 'Cancellato Correttamente');
    //toast.present();
  }
  return response;
}, async (error: any) => {
  loading.dismiss();
  const toast = await toastController.create({
    message: '',
    duration: 1500,
    position: 'top'
  });

  if (error && error.response) {
    toast.setAttribute('color', 'danger')
    if (error.response.status == '401') {
      if (error.config && error.config.url && error.config.url.includes('login')) {
        if (error.response.data && error.response.data.message) {
          toast.setAttribute('message', error.response.data.message.toLowerCase().includes('unauthorized') ? 'Email o Password errati!' : error.response.data.message);
          toast.present();
        }
      } else {
        toast.setAttribute('message', error.response.data.message? error.response.data.message : error.message);
        toast.present();
        store.dispatch('auth/logout');
      }
    }
    if (error.response.status == '403') {
      toast.setAttribute('message', error.response.data.message? error.response.data.message : error.message);
      toast.present();
    }
    if (error.response.status == '400') {
      toast.setAttribute('message', error.response.data.message? error.response.data.message : error.message);
      toast.present();
    }
    if (error.response.status == '422') {
      const errors = error.response.data.errors;
      Object.keys(errors).forEach(item => {
        toast.setAttribute('message', errors[item][0]);
        toast.present();
      })
    }
  }
  return Promise.reject(error);
});
